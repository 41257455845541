<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-3">
            <c-label-text title="자재명" :value="review.materialName"></c-label-text>
          </div>
          <div class="col-3">
            <c-label-text title="자재코드" :value="review.materialCd"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="tabheight"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :popupParam.sync="popupParam"
            :review.sync="review"
            :disabled.sync="disabled"
            :fileLength.sync="fileLength"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chem-review-etc',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',
      }),
    },
    review: {
      type: Object,
      default: () => ({
        mdmChemReviewId: '',  // 사전검토 일련번호
        reviewRequestDate: '',  // 검토요청일
        plantCds: '',  // 사업장코드
        materialCd: '',  // 자재코드
        materialName: '',  // 자재명(KOR)
        materialNameEn: '',  // 자재명(ENG)
        deliveryVendorCd: null,  // 공급업체 코드
        deliveryVendorName: '',  // 공급업체명
        mfgVendorCd: null,  // 제조업체 코드
        mfgVendorName: '',  // 제조업체명
        mdmChemReviewStepCd: null,  // 사전검토 진행상태
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        psmFlag: 'N',  // PSM 대상 여부
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        returnRemark: '',  // 반려사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        subs: [],
        prodReguls: [],
        bills: [],
        checks: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fileLength: {
      type: Object,
      default: () => ({
        msds: 0,
        ingrdient: 0,
      }),
    },
    btnEditable: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      // tabheight: '',
      tab: 'chemAttachFile',
      tabItems: [
        { name: 'chemAttachFile', icon: 'drive_folder_upload', label: 'LBLATTACHMENT', component: () => import(`${'./chemAttachFile.vue'}`) },
        { name: 'chemLaw', icon: 'gavel', label: '규제사항', component: () => import(`${'./chemReviewLaw.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabheight() {
      let _margin = 160;
      if (this.btnEditable) {
        _margin += 50;
      }
      return String(this.contentHeight - _margin) + 'px';
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // this.tabheight = (window.innerHeight - 200) + 'px'
    },
  }
};
</script>